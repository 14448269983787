var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-manage-base',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$route.name))]},proxy:true},{key:"default",fn:function(){return [_c('v-card',{staticClass:"mx-10 mt-5",attrs:{"shaped":"","tile":""}},[_c('Table',{ref:"table",attrs:{"total":_vm.getTotalWebRequestVisitList,"headers":_vm.headers,"items":_vm.webRequestListVisitreservation,"attr":{
          dense: true,
          'no-data-text': _vm.$t('rules.noData'),
          'item-key': 'id',
          class: 'elevation-1 custom-table mt-n4',
        },"itemsPerPage":10,"funReset":_vm.loadList,"sortField":[],"filter":_vm.filter,"itemsPerPageOptions":[10, 20, 50, 100, 200, 500],"multiSort":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":10}},[_c('v-text-field',{staticClass:"ml-8 v-text-field--custom",attrs:{"append-icon":"mdi-magnify","label":"検索","single-line":"","hide-details":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('v-col',{attrs:{"cols":2}},[_c('v-checkbox',{staticClass:"ml-16 v-checkbox--custom",attrs:{"color":"#1CACE0","label":_vm.$t(
                    'webPageManage.VisitReservation.AlsoDisplayedAsSupported'
                  )},model:{value:(_vm.getChecked),callback:function ($$v) {_vm.getChecked=$$v},expression:"getChecked"}})],1)],1)]},proxy:true},{key:"item.handledAt",fn:function(ref){
                  var item = ref.item;
return [_c('v-chip',{staticClass:"status",attrs:{"color":item.handledAt !== null ? '#616161' : '#DB3069',"label":"","text-color":"white"}},[_vm._v(" "+_vm._s(item.handledAt !== null ? '対応済' : '未対応')+" ")])]}},{key:"item.createdAt",fn:function(ref){
                  var item = ref.item;
return [(item.createdAt !== null)?_c('div',[_vm._v(" "+_vm._s(item.createdAt.substr(5, 2))+"/"+_vm._s(item.createdAt.substr(8, 2))+" ")]):_vm._e()]}},{key:"item.name",fn:function(ref){
                  var item = ref.item;
return [(item.name !== null)?_c('div',[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e()]}},{key:"item.tel",fn:function(ref){
                  var item = ref.item;
return [(item.tel !== null)?_c('div',[_vm._v(" "+_vm._s(item.tel)+" ")]):_vm._e()]}},{key:"item.email",fn:function(ref){
                  var item = ref.item;
return [(item.email !== null)?_c('div',[_vm._v(" "+_vm._s(item.email)+" ")]):_vm._e()]}},{key:"item.firstDate",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-start"},[(item.firstDate !== null)?_c('div',{staticClass:"mr-2"},[_c('span',[_vm._v(_vm._s(item.firstDate.substr(5, 2))+"/"+_vm._s(item.firstDate.substr(8, 2)))])]):_vm._e(),(item.firstTime !== null)?_c('div',[_c('span',[_vm._v(_vm._s(item.firstTime.substr(0, 5)))])]):_vm._e()])]}},{key:"item.secondDate",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-start"},[(item.secondDate !== null)?_c('div',{staticClass:"mr-2"},[_c('span',[_vm._v(_vm._s(item.secondDate.substr(5, 2))+"/"+_vm._s(item.secondDate.substr(8, 2)))])]):_vm._e(),(item.secondTime !== null)?_c('div',[_c('span',[_vm._v(_vm._s(item.secondTime.substr(0, 5)))])]):_vm._e()])]}},{key:"item.detail",fn:function(ref){
                  var item = ref.item;
return [(item.client)?_c('v-btn',{staticClass:"t-btn--prm",attrs:{"icon":"","to":_vm.checkPerUser
                ? '/customer-details/' +
                  item.clientId +
                  '?typeClient=' +
                  item.client.type
                : '',"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-account")])],1):_vm._e()]}},{key:"item.clientId",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-start"},[(item.clientId === null)?_c('span',[_vm._v("なし")]):_vm._e(),(item.clientId !== null && item.wasExistingClient)?_c('span',[_vm._v("紐づけ済み")]):_vm._e(),(item.clientId !== null && !item.wasExistingClient)?_c('span',[_vm._v("作成済み")]):_vm._e()])]}},{key:"item.edit",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{staticClass:"btn-crm-primary",staticStyle:{"border":"0px"},attrs:{"disabled":!_vm.checkPerUser,"icon":""}},[_c('v-icon',{attrs:{"x-large":""},on:{"click":function($event){_vm.checkPerUser ? _vm.showDetail(item.id) : ''}}},[_vm._v(" mdi-pencil-circle ")])],1)]}}])})],1),(_vm.checkPerUser && _vm.visible)?_c('WebVisitReservationDetail',{attrs:{"visible":_vm.visible,"id":_vm.idDetail},on:{"fetchList":function($event){return _vm.$refs.table.reset()},"close":function($event){_vm.visible = false}}}):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }