<template>
  <page-manage-base>
    <template v-slot:title>{{ $route.name }}</template>
    <template v-slot:default>
      <v-card class="mx-10 mt-5" shaped tile>
        <Table
          :total="getTotalWebRequestVisitList"
          :headers="headers"
          :items="webRequestListVisitreservation"
          :attr="{
            dense: true,
            'no-data-text': $t('rules.noData'),
            'item-key': 'id',
            class: 'elevation-1 custom-table mt-n4',
          }"
          ref="table"
          :itemsPerPage="10"
          :funReset="loadList"
          :sortField="[]"
          :filter="filter"
          :itemsPerPageOptions="[10, 20, 50, 100, 200, 500]"
          :multiSort="true"
        >
          <template v-slot:top>
            <v-row>
              <v-col :cols="10">
                <v-text-field
                  v-model="searchText"
                  append-icon="mdi-magnify"
                  label="検索"
                  class="ml-8 v-text-field--custom"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col :cols="2">
                <v-checkbox
                  v-model="getChecked"
                  color="#1CACE0"
                  class="ml-16 v-checkbox--custom"
                  :label="
                    $t(
                      'webPageManage.VisitReservation.AlsoDisplayedAsSupported',
                    )
                  "
                ></v-checkbox>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.handledAt="{ item }">
            <v-chip
              :color="item.handledAt !== null ? '#616161' : '#DB3069'"
              class="status"
              label
              text-color="white"
            >
              {{ item.handledAt !== null ? '対応済' : '未対応' }}
            </v-chip>
          </template>
          <template v-slot:item.createdAt="{ item }">
            <div v-if="item.createdAt !== null">
              {{ item.createdAt.substr(5, 2) }}/{{
                item.createdAt.substr(8, 2)
              }}
            </div>
          </template>
          <template v-slot:item.name="{ item }">
            <div v-if="item.name !== null">
              {{ item.name }}
            </div>
          </template>
          <template v-slot:item.tel="{ item }">
            <div v-if="item.tel !== null">
              {{ item.tel }}
            </div>
          </template>
          <template v-slot:item.email="{ item }">
            <div v-if="item.email !== null">
              {{ item.email }}
            </div>
          </template>
          <template v-slot:item.firstDate="{ item }">
            <div class="d-flex justify-start">
              <div v-if="item.firstDate !== null" class="mr-2">
                <span
                  >{{ item.firstDate.substr(5, 2) }}/{{
                    item.firstDate.substr(8, 2)
                  }}</span
                >
              </div>
              <div v-if="item.firstTime !== null">
                <span>{{ item.firstTime.substr(0, 5) }}</span>
              </div>
            </div>
          </template>
          <template v-slot:item.secondDate="{ item }">
            <div class="d-flex justify-start">
              <div v-if="item.secondDate !== null" class="mr-2">
                <span
                  >{{ item.secondDate.substr(5, 2) }}/{{
                    item.secondDate.substr(8, 2)
                  }}</span
                >
              </div>
              <div v-if="item.secondTime !== null">
                <span>{{ item.secondTime.substr(0, 5) }}</span>
              </div>
            </div>
          </template>
          <template v-slot:item.detail="{ item }">
            <v-btn
              v-if="item.client"
              class="t-btn--prm"
              icon
              :to="
                checkPerUser
                  ? '/customer-details/' +
                    item.clientId +
                    '?typeClient=' +
                    item.client.type
                  : ''
              "
              target="_blank"
            >
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.clientId="{ item }">
            <div class="d-flex justify-start">
              <span v-if="item.clientId === null">なし</span>
              <span v-if="item.clientId !== null && item.wasExistingClient"
                >紐づけ済み</span
              >
              <span v-if="item.clientId !== null && !item.wasExistingClient"
                >作成済み</span
              >
            </div>
          </template>
          <template v-slot:item.edit="{ item }">
            <v-btn
              :disabled="!checkPerUser"
              class="btn-crm-primary"
              style="border: 0px"
              icon
            >
              <v-icon @click="checkPerUser ? showDetail(item.id) : ''" x-large>
                mdi-pencil-circle
              </v-icon>
            </v-btn>
          </template>
        </Table>
      </v-card>
      <WebVisitReservationDetail
        v-if="checkPerUser && visible"
        :visible="visible"
        :id="idDetail"
        @fetchList="$refs.table.reset()"
        @close="visible = false"
      ></WebVisitReservationDetail>
    </template>
  </page-manage-base>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import PageManageBase from '../PageManageBase.vue';
import WebVisitReservationDetail from './WebVisitReservationDetail.vue';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import Table from '@/components/Table/index.vue';

export default {
  name: 'VisitReservationList',
  components: {
    PageManageBase,
    WebVisitReservationDetail,
    Table,
  },
  data() {
    return {
      filter: {
        keyword: '',
        handled: false,
      },
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      searchText: '',
      idDetail: null,
      visible: false,
      getChecked: false,
      // Table
      headers: [
        {
          text: this.$t('webPageManage.VisitReservation.status'),
          fieldName: 'webRequest.isHandled',
          value: 'handledAt',
          class: '',
          align: 'center',
        },
        {
          text: this.$t('webPageManage.VisitReservation.date'),
          fieldName: 'webRequest.createdAt',
          value: 'createdAt',
          class: '',
        },
        {
          text: '名前',
          sortable: false,
          value: 'name',
          class: '',
          filterable: true,
        },
        {
          text: this.$t('webPageManage.VisitReservation.telephoneNumber'),
          fieldName: 'webRequest.tel',
          value: 'tel',
          class: '',
        },
        {
          text: this.$t('webPageManage.VisitReservation.Email'),
          fieldName: 'webRequest.email',
          value: 'email',
          class: '',
        },
        {
          text: this.$t('webPageManage.VisitReservation.FirstChoiceDate'),
          fieldName: 'webRequest.firstDate',
          value: 'firstDate',
          class: '',
        },
        {
          text: this.$t('webPageManage.VisitReservation.secondChoiceDate'),
          fieldName: 'webRequest.secondDate',
          value: 'secondDate',
          class: '',
        },
        {
          text: this.$t(
            'webPageManage.VisitReservation.VisitReservationDetails',
          ),
          sortable: false,
          value: 'edit',
          class: '',
          align: 'center',
        },
        {
          text: this.$t('webPageManage.VisitReservation.client'),
          sortable: false,
          value: 'clientId',
          class: '',
        },
        {
          text: this.$t('webPageManage.VisitReservation.CustomerDetails'),
          sortable: false,
          value: 'detail',
          class: '',
          align: 'center',
        },
      ],
    };
  },

  watch: {
    searchText(newVal) {
      clearTimeout(this.timeoutSearch);
      this.timeoutSearch = setTimeout(async () => {
        this.filter.keyword = newVal;
        this.$refs.table.reset();
      }, 1000);
    },
    async getChecked(newVal) {
      if (newVal) {
        this.filter.handled = null;
      } else {
        this.filter.handled = false;
      }
      this.$refs.table.reset();
    },
  },
  computed: {
    ...mapGetters([
      'webRequestListVisitreservation',
      'checkedVisitReservation',
      'getTotalWebRequestVisitList',
    ]),
  },
  methods: {
    ...mapActions([
      'getWebRequestListVisitreservation',
      'getWebReqEditVisitreservation',
    ]),
    ...mapMutations([
      'setCheckedVisitReservation',
      'setWebReqEditVisitreservation',
      'setWebRequestVisitListTake',
      'setWebRequestVisitListSkip',
      'setTotalWebRequestVisitList',
      'setKeywordVisit',
    ]),

    async loadList(variables) {
      if (variables) {
        await this.getWebRequestListVisitreservation(variables);
      }
    },

    async showDetail(id) {
      this.idDetail = id;
      this.setWebReqEditVisitreservation({});
      this.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.notice {
  max-width: 1258px !important;
  margin-top: 15px;
  margin-left: 42px;
  .addNew {
    color: white;
    height: 28px !important;
    min-width: 40px;
  }
  .card-title {
    label {
      font-size: 13px;
    }
  }
  .custom-table {
    .status {
      height: 27px;
      width: 63px;
      border-radius: 6px !important;
    }
    button,
    a {
      height: 40px !important;
      width: 80px;
      font-size: 15px;
      color: white;
    }
  }
}
.v-data-table::v-deep {
  th,
  .v-data-footer,
  .v-select__selection {
    font-size: 15px !important;
  }
  th {
    color: #424242 !important;
    font-size: 14px !important;
  }
  td {
    color: #424242 !important;
    font-size: 14px !important;
  }
  .v-data-footer__pagination {
    display: none;
  }
}
::v-deep {
  .v-text-field__slot {
    .v-label {
      font-size: 15px !important;
    }
  }
  .v-data-table-header__icon {
    color: #000 !important;
  }
}
</style>

<style lang="scss">
.v-text-field--custom {
  .v-label {
    color: #757575;
    font-size: 13px;
  }
  .v-icon {
    color: #757575;
  }
}
.v-checkbox--custom {
  label {
    color: #666666 !important;
    font-size: 18px !important;
  }
}
</style>
