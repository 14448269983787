<template>
  <div>
    <div>
      <div class="px-10 py-3 grey lighten-4">
        <h1 class="page-title-list">
          <div class="d-flex justify-space-between">
            <div>
              <slot name="title" />
            </div>
            <div>
              <slot name="btnSave" />
            </div>
          </div>

          <slot name="actions" />
        </h1>
      </div>
      <template>
        <v-tabs v-model="tab" align-with-title>
          <v-tabs-slider color="#13ACE0" ></v-tabs-slider>
          <v-tab
            v-for="(item, i) in items"
            :key="i"
            :to="item.to"
          >
          <!-- class="page-title-tab" -->
            {{ item.title }}
            <span
              v-if="
              (i === 0 && showDot.duplicatedClientList) ||
              (i === 1 && showDot.vm) ||
              (i === 3 && showDot.visit) ||
              (i === 4 && showDot.online) ||
              (i === 5 && showDot.trial)"
              style="color: #c92d2d"
              class="ml-2 mb-4"
              >
              <i class="fas fa-circle"></i>
            </span>
          </v-tab>
        </v-tabs>
        <slot name="default" />
      </template>
    </div>
  </div>
</template>
<script>
import { checkPermissionTab } from '@/utils/permissions'

import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'PageManageBase',
  data() {
    return {
      title: 'WebPageManage',
      tab: null,
      items: [],
      itemsTmp: [
        {
          title: '既存顧客重複',
          to: '/web-manage/info',
          pathRoot: '/web-manage/info',
          order: 1
        },
        {
          title: 'VM資料請求',
          to: '/web-manage/document-request',
          pathRoot: '/web-manage/document-request',
          order: 2
        },
        {
          title: 'VS資料請求',
          to: '/web-manage/old-customer',
          pathRoot: '/web-manage/old-customer',
          order: 3
        },
        {
          title: '来店予約',
          to: '/web-manage/visit-reservation',
          pathRoot: '/web-manage/visit-reservation',
          order: 4
        },
        {
          title: 'オンライン相談',
          to: '/web-manage/experience',
          pathRoot: '/web-manage/experience',
          order: 5
        },
        {
          title: '体験宿泊',
          to: '/web-manage/facility',
          pathRoot: '/web-manage/facility',
          order: 6
        },
        {
          title: 'お知らせ',
          to: '/web-manage/notice',
          pathRoot: '/web-manage/notice',
          order: 7
        },
        {
          title: '施設設定',
          to: '/web-manage/facility-setting',
          pathRoot: '/web-manage/facility-setting',
          order: 8
        },
        {
          title: '会員Web資料',
          to: '/web-manage/web-document',
          pathRoot: '/web-manage/web-document',
          order: 9
        },
      ],
      showDot: {
        duplicatedClientList: false,
        online: false,
        trial: false,
        visit: false,
        vm: false,
      }
    };
  },
  computed: {
    ...mapGetters([
      'getDuplicatedClientList',
      'webRequestlistDocumentRequestlistFalse',
      'webRequestListVisitreservation',
      'webrequestlistExperience',
      'webrequestlistFacility'
    ]),
  },
  mounted () {
    this.checkPerTab()
    this.actionShowRedDotDuplicatedClientList().then(result => {
      // #既存顧客重複
      if (result.duplicatedClientList.items.length > 0) {
        this.showDot.duplicatedClientList = true
      } else {
        this.showDot.duplicatedClientList = false
      }
      // # オンライン相談
      if (result.online.items.length > 0) {
        this.showDot.online = true
      } else {
        this.showDot.online = false
      }
      // #体験宿泊
      if (result.trial.items.length > 0) {
        this.showDot.trial = true
      } else {
        this.showDot.trial = false
      }
      // #来店予約
      if (result.visit.items.length > 0) {
        this.showDot.visit = true
      } else {
        this.showDot.visit = false
      }
      // #VM資料請求
      if (result.vm.items.length > 0) {
        this.showDot.vm = true
      } else {
        this.showDot.vm = false
      }
    })
  },
  methods: {
    ...mapActions(['actionShowRedDotDuplicatedClientList']),
    checkPermissionTab,
    checkPerTab () {
      let itemCheck = this.checkPermissionTab(this.itemsTmp)
      itemCheck = [...new Set(itemCheck)]
      itemCheck.sort((a, b) => a.order - b.order)
      this.items = itemCheck
    }
  }
};
</script>

<style lang="scss" scoped>
.page-title-list{
  font-size: 20px !important;
  color: #000000 !important;
  font-weight: bold;
}
.v-tab{
  font-size: 16px;
  font-weight: 600 !important;
}
.v-tab--active {
  background-color: #ffffff !important;
  color: #13ace0 !important;
  border-bottom: 2px solid #13ACE0;
}

.v-tab--active:hover {
  background-color: #ceeffb !important;
  color: #13ace0 !important;
  // border-bottom: 2px solid red;
}

.v-tab:not(.v-tab--active) {
  background-color: #ffffff;
  color: #aaaaaa;
  &:hover {
    background-color: #eeeeee;
    // color: #13ace0 !important;
  }
}
::v-deep{
  .v-tabs-slider-wrapper{
    display: none !important;
  }
}
</style>
