<template>
  <div>
    <div class="boder_gray_padding" style="font-size: 14px !important">
      <div style="color: #757575">
        その他日時
      </div>
      <div
        class="font-weight-bold"
        style="color: #212121"
      >
        {{ item.otherDateTime }}
      </div>
    </div>
    <div class="padding" style="font-size: 14px">
      <div style="color: #757575">
        ご相談内容
      </div>
      <div
        class="font-weight-bold"
        style="color: #212121"
      >
        <span>
          {{ item.inquiry }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object
  }
}
</script>

<style lang="scss" scoped>
.padding {
  padding: 10px 0 10px 20px;
}
</style>
