<template>
  <div>
    <v-dialog v-model="dialog">
      <div class="d-flex justify-space-between">
        <h2 style="font-size: 20px !important;color: #000000 !important;">
          来店予約
        </h2>
        <h4 style="font-size: 17px !important;color: #616161 !important;">
          受信日:
          <span class="ml-4" v-if="webReqEditVisitreservation.createdAt">
            {{ formatDateTimeZone(webReqEditVisitreservation.createdAt) | formatDatetime}}
          </span>
        </h4>
      </div>
      <div class="d-inline-flex">
        <p class="mt-5 icon-alert" style="font-size: 16px !important;color: #666666 !important;">
          {{ $t('webPageManage.experience.status') }}
        </p>
        <v-col>
          <v-select
            style="width: 130px; color: #666666"
            v-model="valStatus"
            :items="['対応済', '未対応']"
            outlined
            dense
          ></v-select>
        </v-col>
      </div>
      <div class="d-flex align-center mt-n3">
        <v-alert
          v-if="webReqEditVisitreservation.clientId === null"
          dense
          color="#0B6786"
          class="white--text font-weight-bold"
          max-width="700"
        >
          <v-row align="center">
            <v-col>
              <v-icon
                dark
                left
              >
                mdi-alert-outline
              </v-icon>
              顧客なし 
            </v-col>
            <v-col class="d-flex">
              <v-btn
                dense
                outlined
                class="white--text"
                @click="visibleCompChirld = true"
              >
                既存顧客に結びつける
              </v-btn>
              <v-btn
                outlined
                dense
                class="ml-2 white--text"
                @click="createClientWeb()"
              >
                新規顧客を作成
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
        <div v-else>
          <v-btn class="btn-customer-details" style="font-size: 14px" @click="redirectCustomerDetail" target="_blank">顧客詳細</v-btn>
        </div>
      </div>
      <!-- content -->
      <v-row class="mt-1">
        <v-col cols="6">
          <v-card class="pa-5">
            <h2>{{ $t('webPageManage.experience.CustomerInformation') }}</h2>
            <v-divider class="mt-5"></v-divider>
            <div>
              <div class="boder_gray_padding d-flex justify-space-between">
                <span class="fz-12">{{ $t('webPageManage.experience.Name') }}</span>
                <span class="mr-5 fz-12">
                  {{
                    webReqEditVisitreservation.lastName
                      ? webReqEditVisitreservation.lastName
                      : ''
                  }}
                  {{
                    webReqEditVisitreservation.firstName
                      ? webReqEditVisitreservation.firstName
                      : ''
                  }}
                </span>
              </div>
            </div>
            <div>
              <div class="boder_gray_padding d-flex justify-space-between">
                <span class="fz-12">{{ $t('webPageManage.experience.Furigana') }}</span>
                <span class="mr-5 fz-12">
                  {{
                    webReqEditVisitreservation.lastKana
                      ? webReqEditVisitreservation.lastKana
                      : ''
                  }}
                  {{
                    webReqEditVisitreservation.firstKana
                      ? webReqEditVisitreservation.firstKana
                      : ''
                  }}
                </span>
              </div>
            </div>
            <div>
              <div class="boder_gray_padding d-flex justify-space-between">
                <span class="fz-12">{{ $t('webPageManage.experience.Birthday') }}</span>
                <span class="mr-5 fz-12">{{
                  webReqEditVisitreservation.birthday
                    ? webReqEditVisitreservation.birthday
                    : ''
                }}</span>
              </div>
            </div>
            <div>
              <div class="boder_gray_padding d-flex justify-space-between">
                <span class="fz-12">{{ $t('webPageManage.experience.PostalCode') }}</span>
                <span class="mr-5 fz-12">{{
                  webReqEditVisitreservation.postalCode
                    ? webReqEditVisitreservation.postalCode
                    : ''
                }}</span>
              </div>
            </div>
            <div class="d-flex justify-space-between">
              <div style="margin: auto; font-size: 12px">
                {{ $t('webPageManage.experience.Address') }}
              </div>
              <div style="width: 560px">
                <div class="boder_gray_padding d-flex justify-space-between">
                  <span class="fz-12"
                    >({{ $t('webPageManage.experience.Prefectures') }})</span
                  >
                  <span class="mr-5 fz-12">{{
                    webReqEditVisitreservation.prefecture
                      ? webReqEditVisitreservation.prefecture
                      : ''
                  }}</span>
                </div>
                <div class="boder_gray_padding d-flex justify-space-between fz-12">
                  <span
                    >({{ $t('webPageManage.experience.CityOrCounty') }})</span
                  >
                  <span class="mr-5">{{
                    webReqEditVisitreservation.locality
                      ? webReqEditVisitreservation.locality
                      : ''
                  }}</span>
                </div>
                <div class="boder_gray_padding d-flex justify-space-between fz-12">
                  <span
                    >({{ $t('webPageManage.experience.TownName') }}・{{
                      $t('webPageManage.experience.address')
                    }})</span
                  >
                  <span class="mr-5">{{
                    webReqEditVisitreservation.address1
                      ? webReqEditVisitreservation.address1
                      : ''
                  }}</span>
                </div>
                <div class="boder_gray_padding d-flex justify-space-between fz-12">
                  <span
                    >({{ $t('webPageManage.experience.BuildingName') }}・{{
                      $t('webPageManage.experience.CondominiumName')
                    }})</span
                  >
                  <span class="mr-5">{{
                    webReqEditVisitreservation.address2
                      ? webReqEditVisitreservation.address2
                      : ''
                  }}</span>
                </div>
              </div>
            </div>
            <div class="d-flex justify-space-between fz-12">
              <div style="margin: auto; font-size: 12px">
                {{ $t('webPageManage.experience.ContactInformation') }}
              </div>
              <div style="width: 560px">
                <div class="boder_gray_padding d-flex justify-space-between fz-12">
                  <span>{{
                    $t('webPageManage.experience.HomePhoneNumber')
                  }}</span>
                  <span class="mr-5">{{
                    webReqEditVisitreservation.tel
                      ? webReqEditVisitreservation.tel
                      : ''
                  }}</span>
                </div>
                <div class="boder_gray_padding d-flex justify-space-between fz-12">
                  <span>{{
                    $t('webPageManage.experience.cellPhoneNumber')
                  }}</span>
                  <span class="mr-5">{{
                    webReqEditVisitreservation.telMobile
                      ? webReqEditVisitreservation.telMobile
                      : ''
                  }}</span>
                </div>
              </div>
            </div>
            <div>
              <div class="boder_gray_padding d-flex justify-space-between fz-12">
                <span>メールアドレス</span>
                <span class="mr-5">{{
                  webReqEditVisitreservation.email
                    ? webReqEditVisitreservation.email
                    : ''
                }}</span>
              </div>
            </div>
            <div class="mt-5 mx-4 fz-12">
              <span style="font-size: 12px !important; color: #000000 !important" class="blod">{{
                $t('webPageManage.experience.remarks')
              }}</span>
              <v-textarea
                v-model="remarksNote"
                rows="3"
                outlined
                hide-details="auto"
                :value="webReqEditVisitreservation.remarks"
                :rules="[$rules.checkTextAreaLength()]"
              ></v-textarea>
            </div>
          </v-card>
        </v-col>
        <v-col cols="6">
          <div class="d-flex flex-column h-100">
            <div class="h-100">
              <v-card class="pa-5">
                <h1 style="font-weight: normal; font-size: 23px">
                  ご来店希望日・ご相談内容
                </h1>
                <v-divider class="mt-5"></v-divider>
                <div>
                  <div
                    class="
                      boder_gray_padding
                      d-flex
                      justify-space-between
                    "
                    style="font-size: 14px"
                  >
                    <span style="color: #757575">
                      第一希望日
                    </span>
                    <span
                      class="d-flex w-50 justify-end font-weight-bold"
                      style="color: #212121"
                    >
                      <span class="w-50">
                        {{ webReqEditVisitreservation.firstDate ? `${webReqEditVisitreservation.firstDate.substr(5,2)}/${webReqEditVisitreservation.firstDate.substr(8,2)}` : '' }}
                      </span>
                      <span class="w-50">
                        {{ webReqEditVisitreservation.firstTime ? `${webReqEditVisitreservation.firstTime.substr(0,5)}` : '' }}
                      </span>
                    </span>
                  </div>
                </div>
                <div>
                  <div
                    class="boder_gray_padding d-flex justify-space-between"
                    style="font-size: 14px"
                  >
                    <span style="color: #757575">
                      第二希望日
                    </span>
                    <span
                      class="d-flex w-50 justify-end font-weight-bold"
                      style="color: #212121"
                    >
                      <span class="w-50">
                        {{ webReqEditVisitreservation.secondDate ? `${webReqEditVisitreservation.secondDate.substr(5,2)}/${webReqEditVisitreservation.secondDate.substr(8,2)}` : '' }}
                      </span>
                      <span class="w-50">
                        {{ webReqEditVisitreservation.secondTime ? `${webReqEditVisitreservation.secondTime.substr(0,5)}` : '' }}
                      </span>
                    </span>
                  </div>
                </div>
                <web-request-detail-inquiry :item="webReqEditVisitreservation" />
              </v-card>
            </div>
            <v-card class="d-flex justify-end mt-6" flat>
              <v-btn large class="t-btn--red-dark" @click="closeDialog" style="font-size: 14px">
                <v-icon class="mr-2">mdi-close</v-icon>
                {{ $t('webPageManage.experience.close') }}
              </v-btn>
              <v-btn large class="t-btn--prm ml-3" style="font-size: 14px;background: #1873D9 !important" @click="saveVisit">
                {{ $t('webPageManage.experience.save') }}
              </v-btn>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <connect-existing-customer
        :visibleCompChirld="visibleCompChirld"
        :id="webReqEditVisitreservation.id"
        @close="visibleCompChirld = false"
        :type="0"
        @reset="getWebReqEditVisitreservation(id)"
      />
    </v-dialog>
  </div>
</template>
<script>
import { 
  WEB_REQUEST_EDIT_VISITRESERVATION, 
  WEB_REQUEST_UPDATE_VISITRESERVATION,
  WEB_REQUEST_CREATE_VISIT_RESERVATION
} from "@/api/graphql/dashboard/webPageManage/VisitReservation/index"
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ConnectExistingCustomer from '../ConnectExistingCustomer.vue';
import moment from 'moment';

import { handlErrorView, getCopyErrorTextView } from '@/constants/functions'
import gql from 'graphql-tag'
import WebRequestDetailInquiry from '../common/WebRequestDetailInquiry.vue';


export default {
  name: 'WebVisitReservationDetail',
  components: {
    ConnectExistingCustomer,
    WebRequestDetailInquiry
  },
  filters: {
    formatDatetime (value) {
      if (value) {
        return moment(value, 'YYYY/MM/DD HH:mm').format('YYYY/MM/DD HH:mm')
      }
      return ''
    },
  },
  data() {
    return {
      remarksNote: '',
      valStatus: '',
      visibleCompChirld: false,
      webReqEditVisitreservation: {}
    };
  },
  props: {
    visible: Boolean,
    id: Number,
  },
  mounted () {
    this.getWebReqEditVisitreservation(this.id)
  },
  computed: {
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },

    ...mapGetters(['webReqEditVisitreservationState'])
  },
  created() {
    if (this.visible) {
     this.getDetail()
    }
  },
  watch: {
    visible() {
      if(this.visible) {
        this.getDetail()
      }
    },

    webReqEditVisitreservationState(){
      this.webReqEditVisitreservation = this.webReqEditVisitreservationState
    }
  },
  methods: {
    handlErrorView,
    getCopyErrorTextView,
     ...mapActions([
      'getWebReqEditVisitreservation'
    ]),
     ...mapMutations([
      'setCopyErrorText',
      'setAlertSuccess',
      'setAlertError',
      'setLoadingOverlayShow',
      'setLoadingOverlayHide',
      'setPermissionUser',
      'setRoleAdminUser'
    ]),
    formatDateTimeZone(date) {
      return moment(date).format('YYYY/MM/DD HH:mm');
    },
    async saveVisit() {
      this.setLoadingOverlayShow()
      const variables = {
            id: parseInt(this.webReqEditVisitreservation.id),
           remarks: this.remarksNote,
           status: this.valStatus === '対応済' ? 'Handled' : 'New'
          }
        await this.$apollo.mutate({
          mutation: gql`${WEB_REQUEST_UPDATE_VISITRESERVATION}`,
          variables: variables,
          fetchPolicy: 'no-cache'
        }).then((data) => {
          this.$store.dispatch('proVisits')
          if (data.data.updateWebRequest) {
            this.setAlertSuccess('更新しました。')
          }
          this.setLoadingOverlayHide();
          this.$emit('fetchList');
          this.$emit('close');
        }).catch(async (error) => {
          this.setLoadingOverlayHide()
          this.setCopyErrorText(this.getCopyErrorTextView(WEB_REQUEST_UPDATE_VISITRESERVATION, variables, error.graphQLErrors))
          const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          } else {
            this.setAlertError('更新失敗')
          }
        })
    },
    redirectCustomerDetail () {
      this.$router.push({name : 'customer-details', params: { id: parseInt(this.webReqEditVisitreservation.clientId) }, query: {idClient: parseInt(this.webReqEditVisitreservation.clientId), typeClient: this.webReqEditVisitreservation.client.type }})
    },
    async createClientWeb () {
      this.setLoadingOverlayShow()
      const variables = {
          id: parseInt(this.id)
        }
      await this.$apollo.mutate({
        mutation: gql`${WEB_REQUEST_CREATE_VISIT_RESERVATION}`,
        variables: variables,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        if (data.data.createClientWebRequest.id) {
          this.getDetail()
        }
        this.setLoadingOverlayHide()
      }).catch(async (error) => {
        this.setLoadingOverlayHide()
        this.setCopyErrorText(this.getCopyErrorTextView(WEB_REQUEST_CREATE_VISIT_RESERVATION, variables, error.graphQLErrors))
        const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
        if (errorTmp) {
          this.setAlertError(errorTmp, { root: true })
        }
      })
    },
    async getDetail() {
      this.setLoadingOverlayShow()
      const variables = {
          id:parseInt(this.id)
        }
      await this.$apollo.query({
        query: gql`${WEB_REQUEST_EDIT_VISITRESERVATION}`,
        variables: variables,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.webReqEditVisitreservation = data.data.webRequest
        if (this.webReqEditVisitreservation.handledAt) {
          this.valStatus = '対応済';
        } else {
          this.valStatus = '未対応';
        }
        this.remarksNote = this.webReqEditVisitreservation.remarks
        this.setLoadingOverlayHide()
      }).catch(async (error) => {
        this.setLoadingOverlayHide()
        this.setCopyErrorText(this.getCopyErrorTextView(WEB_REQUEST_EDIT_VISITRESERVATION, variables, error.graphQLErrors))
        const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
        if (errorTmp) {
          this.setAlertError(errorTmp, { root: true })
        }
      })
    },
    closeDialog() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .boder_gray_padding {
  padding: 10px 0 10px 20px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 500;
  color: #000;
  font-size: 10px;
}
h1 {
  color: #333333;
  font-weight: 400 !important;
}
h2 {
  font-size: 20px;
  color: #000;
  font-weight: bold;
}
h4 {
  font-size: 17px;
  font-weight: 400;
  color: #616161;
}
.h-100 {
  height: 100%;
}
.v-btn {
  font-size: 12px;
  height: 32px !important;
  padding: 10px 8px !important;
}
.div-alert {
  background-color: #0b6786;
  border-radius: 10px;
  color: white;
  width: 387px;
  height: 46px;
  line-height: 46px;
  padding: 0 15px;
}

::v-deep {
  .v-btn__content {
    font-size: 14px !important;
  }
  .v-select.v-input--dense .v-select__selection--comma {
    color: #666666 !important;
    font-size: 16px !important;
  }
  .btn-customer-details {
    background-color: #757575 !important;
    font-size: 10px;
    color: #fafafa;
  }
  .icon-alert {
    font-size: 16px;
    color: #666666;
  }
  .btn-div-alert {
    background-color: #0b6786 !important;
    color: white;
    border: 1px solid white;
    max-width: 137px;
  }
  .v-dialog {
    width: 1500px;
    background-color: white;
    padding: 30px;
    border-radius: 9px;
  }
  .v-select__selection--comma {
    font-size: 16px !important;
    margin: 0 !important;
  }
  .w-50 {
    width: 37%;
  }
  .mr-5 {
    margin-right: 20px !important;
  }
  // .v-text-field--enclosed .v-input__append-inner {
  //   margin: auto !important;
  // }
  .v-icon__svg {
    color: #424242;
  }
  // .v-text-field--outlined.v-input--is-focused fieldset {
  //   border: 2px solid #9e9e9e !important;
  // }
  .fz-12 {
    font-size: 12px;
  }
}
</style>
